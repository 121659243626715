<template>
    <div class="pcdiy-box"  :style="'background-image: url('+info.bg_img+');background-repeat: '+(info.bg_repeat==1?'repeat':'no-repeat')+';background-color:'+info.bg_colour+';'" v-loading="loading">
        <div v-for="(item) in viewContent" :key="item.id">
            <div v-if="item.block_id == 1" v-html="item.content"></div>
            <div v-if="item.block_id == 2 && item.goods && item.goods.length > 0" class="list-wrap" :style="item.style">
                <div class="goods-info">
                    <div class="item" v-for="(citem, cindex) in item.goods" :key="cindex"
                        @click="$router.pushToTab({ path: '/sku-' + citem.id + '?source_type=0' })">
                        <div class="goods-wrap">
                            <div class="img-wrap"><img alt="商品图片" :src="$img(citem.image, { size: 'mid' })"
                                    @error="imageError(cindex)" /></div>
                            <h3>
                                <span class="p_tag_832" v-if="citem.tag_type == 1">832产品</span>
                                <span class="p_tag_832" v-if="citem.tag_level == 2">省贫认定</span>
                                <span class="p_tag_832" v-if="citem.tag_level == 3">市贫认定</span>
                                <span class="p_tag_832" v-if="citem.tag_level == 4">县贫认定</span>
                                {{ citem.title }}
                            </h3>
                            <p class="price">
                                <label>￥</label>{{ citem.price }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="item.block_id == 3"  class="block3" :style="'padding:'+item.content.pt+' '+item.content.pl+';background-color:'+item.content.bg">
                <div class="title" v-if="item.content.title">{{ item.content.title }}</div>
                <div class="box">
                    <div class="item" v-for="(it,index) in item.content.list" :key="index">
                        <a :href="it.url">
                            <div class="img"><img :src="it.img" alt=""></div>
                            <div class="text">{{ it.title }}</div>
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="item.block_id == 4"  class="block4">
                <div class="goods_box" :style="'background-image: url('+item.floor_img+')'">
                    <div class="content">
                        <template v-for="(citem, cindex) in item.goods">
                            <div class="item" v-if="cindex<3" :key="cindex"
                            @click="$router.pushToTab({ path: '/sku-' + citem.id + '?source_type=0' })">
                                <div class="goods-wrap">
                                    <div class="img-wrap">
                                        <img alt="商品图片" :src="$img(citem.image, { size: 'mid' })"
                                            @error="imageError(cindex)" />
                                    </div>
                                    <h3>
                                        {{ citem.title }}
                                    </h3>
                                    <p class="price">
                                        <label>￥</label>{{ citem.price }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="goods_box" :style="'background-image: url('+item.style+')'">
                    <div class="content">
                        <template v-for="(citem, cindex) in item.goods">
                            <div class="item" v-if="cindex>2 && cindex<6" :key="cindex"
                            @click="$router.pushToTab({ path: '/sku-' + citem.id + '?source_type=0' })">
                                <div class="goods-wrap">
                                    <div class="img-wrap">
                                        <img alt="商品图片" :src="$img(citem.image, { size: 'mid' })"
                                            @error="imageError(cindex)" />
                                    </div>
                                    <h3>
                                        {{ citem.title }}
                                    </h3>
                                    <p class="price">
                                        <label>￥</label>{{ citem.price }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>
                    
                </div>
            </div>
            <div v-if="item.block_id == 5"  :style="'height:'+item.content">
            </div>
            <div v-if="item.block_id == 6"  width="100%">
                <img :src="item.content" width="100%" alt="">
            </div>

        </div>
        <div style="height:30px;"></div>
        <div class="bottom-icon">
            <div class="item">
                <p><img src="@/assets/images/icon-bottom-1.jpg" alt=""></p>
                <p><b>精准帮扶</b></p>
            </div>
            <div class="item">
                <p><img src="@/assets/images/icon-bottom-2.jpg" alt=""></p>
                <p><b>售后无忧</b></p>
            </div>
            <div class="item">
                <p><img src="@/assets/images/icon-bottom-3.jpg" alt=""></p>
                <p><b>产地保证</b></p>
            </div>
            <div class="item">
                <p><img src="@/assets/images/icon-bottom-4.jpg" alt=""></p>
                <p><b>双向信用</b></p>
            </div>
        </div>
    </div>
</template>

<script>
import { getViewByPcdiy } from "@/api/goods/goods"
export default {
    name: "list",
    components: {

    },
    computed: {

    },
    data: () => {
        return {
            info:{
                bg_colour:'',
                bg_img:'',
                bg_repeat:''
            },
            loading: true,
            viewContent: [],
        }
    },
    mounted() {
        let pcdiy_viewid = parseInt(this.$route.query.view_id)
        this.getViewContent(pcdiy_viewid)
    },
    methods: {
        getViewContent(pcdiy_viewid) {
            getViewByPcdiy({ id: pcdiy_viewid })
                .then(res => {
                    if (res.code == 0)
                        this.info.bg_colour = res.data.view_info.bg_colour;
                        this.info.bg_img = res.data.view_info.bg_img;
                        this.info.bg_repeat = res.data.view_info.bg_repeat;
                        this.viewContent = res.data.view_info['view_floor']
                    // console.log('==',res)
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
    }
};
</script>
<style lang="scss" scoped>
.pcdiy-box {
    width: 100%;
    min-height: 400px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    background-size: 100%;
}
.block3{
    width: $width;
    margin: 40px auto;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 30px;
    .title{
        padding: 0 0 35px 0;
        font-size: 49px;
        line-height: 50px;
        font-family: Alibaba PuHuiTi;
        // font-weight: 100;
        color: #303030;
        text-align: center;
    }
    .box{
        display: flex;
        justify-content: space-between;
        .item{
            width: 118px;
            text-align: center;
            .img{
                width: 118px;
                height: 118px;
                img{
                    width: 118px;
                    height: 118px;
                    border-radius: 30px;
                }
            }
            .text{
                height: 24px;
                font-size: 25px;
                font-family: Alibaba PuHuiTi;
                // font-weight: 300;
                color: #303030;
                line-height: 30px;
                padding-top: 9px;
                letter-spacing:3px;
            }
        }
    }
}
.block4{
    width: 1210px;
    margin: 40px auto 25px auto;
    display: flex;
    .goods_box{
        width: 580px;
        margin: 0 12px;
        padding-top: 120px;
        background-size: 100%;
        background-repeat: no-repeat;        
        .content{
            margin: 0 18px;
            padding: 18px 15px;
            background-color: #caeed4;
            border-radius: 10px;
            display: flex;
            .item {
            width: 33.3%;
            margin: 0;
            cursor: pointer;
            padding: 0;
            transition: all 0.2s linear;

            .goods-wrap {
                margin: 0 3px 0;
                padding: 15px 0px;
                box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                background: #fff;

                &:hover {
                    z-index: 2;
                    -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
                    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
                    -webkit-transform: translate3d(0, -2px, 0);
                    transform: translate3d(0, -2px, 0);
                }

                .img-wrap {
                    width: 100%;
                    height: 180px;
                    margin: 0px auto;
                    line-height: 180px;
                    /*box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);*/
                    /*border-radius: 16px;*/
                    overflow: hidden;
                    position: relative;

                    img {
                        border: none;
                        position: absolute;
                        margin-right: -75px;
                        top: 50%;
                        left: 50%;
                        display: block;
                        transform: translate(-50%, -50%);
                    }
                }

                h3 {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    height: 44px;
                    text-align: left;
                    -webkit-box-orient: vertical;
                    margin: 8px 15px 3px;

                    .p_tag_832 {
                        background-color: #e1251b;
                        border-radius: 2px;
                        color: #fff;
                        padding: 0 5px;
                        margin-right: 4px;
                        line-height: 16px;
                        font-size: 12px;
                        font-family: Helvetica Neue, Hiragino Sans GB, SimSun, serif;
                    }
                }

                .price {
                    margin: 0 10px;
                    font-size: 18px;
                    line-height: 20px;
                    text-align: left;
                    color: $base-color;
                    font-weight: bold;

                    label {
                        font-size: 14px;
                    }
                }
            }
        }
        }
        
    }
}
.list-wrap {
    width: 1210px;
    overflow: hidden;
    min-height: 500px;
    padding: 35px 0px;
    margin: 0 auto;

    .goods-info {
        width: 1160px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 20%;
            margin: 0;
            cursor: pointer;
            padding: 0;
            transition: all 0.2s linear;

            .goods-wrap {
                margin: 0 10px 20px;
                padding: 15px 0px;
                box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                min-height: 250px;
                background: #fff;

                &:hover {
                    z-index: 2;
                    -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
                    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
                    -webkit-transform: translate3d(0, -2px, 0);
                    transform: translate3d(0, -2px, 0);
                }

                .img-wrap {
                    width: 180px;
                    height: 180px;
                    margin: 0px auto;
                    line-height: 180px;
                    /*box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);*/
                    /*border-radius: 16px;*/
                    overflow: hidden;
                    position: relative;

                    img {
                        border: none;
                        position: absolute;
                        margin-right: -75px;
                        top: 50%;
                        left: 50%;
                        display: block;
                        transform: translate(-50%, -50%);
                    }
                }

                h3 {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    height: 44px;
                    text-align: left;
                    -webkit-box-orient: vertical;
                    margin: 8px 15px 3px;

                    .p_tag_832 {
                        background-color: #e1251b;
                        border-radius: 2px;
                        color: #fff;
                        padding: 0 5px;
                        margin-right: 4px;
                        line-height: 16px;
                        font-size: 12px;
                        font-family: Helvetica Neue, Hiragino Sans GB, SimSun, serif;
                    }
                }

                .price {
                    margin: 0 10px;
                    font-size: 18px;
                    line-height: 20px;
                    text-align: left;
                    color: $base-color;
                    font-weight: bold;

                    label {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.bottom-icon{
        width: 1210px;
        height: 124px;
        margin: 0 auto 15px auto;
        padding: 22px 0;
        background: #FFFFFF;
        display: flex;
        box-sizing: border-box;
        .item{
            width: 25%;
            text-align: center;
            img{
                width: 40px;
            }
        }
    }
</style>
